@import "../../index.css";

.navigation {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
    background-color: transparent;
    font-size: 1em;
    font-weight: bold;
    z-index: 100;
}

.navigation-menu {
    margin-left: auto;
    background-color: transparent;
}

.navigation-menu ul {
    display: flex;
    padding: 0;
}

.navigation-menu li {
    list-style-type: none;
    float: right;
}

.navigation-menu li a {
    text-decoration: none;
    width: 100%;
    margin: 0 1rem;
}

.hamburger {
    top: 10px;
    border: 0;
    padding: 0.5rem;
    color: var(--lightgreen);
    cursor: pointer;
    position: absolute;
    right: 25px;
    font-size: x-large;
    display: none;
    background-color: transparent;
}

.hamburger:hover {}

@media screen and (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .navigation-menu ul {
        position: absolute;
        top: 41px;
        right: 0;
        flex-direction: row;
        width: 50%;
        height: calc(100vh - 77px);
    }

    .navigation-menu.expanded ul {
        display: block;
    }

    .navigation-menu ul {
        display: none;
    }

    .navigation-menu li {
        text-align: center;
        float: none;
        margin: 0;
        background-color: white;
        padding: 1rem 0;
        border: 1px solid black;
    }

    .navigation-menu li a {
        color: black;
        width: 100%;
    }

    .navigation-menu li:hover {
        background-color: #eee;
    }
}

.background-transparent {
    background-color: transparent;
}

.background-black {
    background-color: #051d1d;
    border-bottom: 2px groove white;
}

.hidden {
    visibility: hidden;
}

.brand {
    margin-left: 30px;
    font-size: x-large;
    background: linear-gradient(30deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Staatliches', cursive;
}