@import "../../index.css";

.ourservices {
    padding: 10px;
    background-color: var(--lightgrey);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.ourservices-showed {
    background-color: var(--dark);
    transition: background-color 5s;
    padding-top: 50px;
}

.section-container1 {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.section-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.section-container3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.no-section {
    position: relative;
    width: 320px;
    margin: 20px;
    color: var(--white);
    padding: 30px;
}


.section-os {
    position: relative;
    width: 320px;
    margin: 20px;
    color: var(--dark);
    padding: 40px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 50px 60px rgb(0 0 0 / 10%);
}

.illustration {
    position: absolute;
    margin-top: -20px;
    top: -40px;
    right: -30px;
    font-size: 20px;
    opacity: 0;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    transition: opacity 1s;
}

.illustration-showed {
    position: absolute;
    margin-top: -20px;
    top: -30px;
    right: -30px;
    font-size: 136px;
    opacity: 1;
    -webkit-transform: rotate(365deg);
    -moz-transform: rotate(365deg);
    -o-transform: rotate(365deg);
    -ms-transform: rotate(365deg);
    transform: rotate(365deg);
    transition: font-size 3s, opacity 3s, trasform 3s, top 3s, right 3s;
}

.no-section h2 {
    font-size: 1.8em;
    font-weight: bold;
}

.no-section p {
    font-weight: 500;
    font-size: 1.1em;
    padding-bottom: 5px;
}

.section-os h2 {
    color: var(--orange);
    font-size: 2em;
    font-weight: 900;
}

.section-os h3 {
    font-weight: 500;
    font-size: 1.2em;
}

.section-os ol {
    padding-left: 16px;
}

.section-os ul {
    padding-left: 10px;
}

.section-os ol li {
    font-weight: 500;
    font-size: 1.1em;
    padding-bottom: 5px;
}

.section-os .p {
    font-weight: 500;
    font-size: 2em;
    padding-bottom: 5px;
    text-decoration: none;
}

@media only screen and (max-width: 414px) {
    .section-container1 {
        flex-direction: column;
    }

    .section-container3 {
        flex-direction: column;
    }

    .no-section h2 {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 730px) and (max-width:1102px) {
    .section-container3 {
        flex-direction: row;
    }
}

@media only screen and (min-width:1103px) {
    .section-container3 {
        flex-direction: column;
    }
}