@import "../../index.css";

.Decoration {
    height: 60px;
    background-color: var(--lightgrey);
    padding: 10px;
}

.Decoration hr {
    border: none;
    border-radius: 25px;
    height: 30px;
    color: linear-gradient(30deg, var(--green) 0%, var(--orange) 100%);
    background-image: linear-gradient(30deg, var(--green) 0%, var(--orange) 100%);
    transition-timing-function: linear;
}