@import "index.css";

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Patua One', cursive;
}

body {
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

a {
  pointer-events: all;
  color: var(--white);
  text-decoration: none;
}

.front {
  font-family: 'Staatliches', cursive;
}

.textMiddleContainer h1 {
  background: linear-gradient(30deg, var(--green) 0%, var(--orange) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#top {
  width: 100%;
  height: 100%;
  min-height: 400px;
  color: var(--lightgreen);
  background: var(--dark);
  position: relative;
}

.textTop {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 1.9em;
}

.textMiddleContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.textMiddle {
  margin: 0;
  padding: 0;
  font-size: 14em;
  letter-spacing: 0.02em;
}

.textBottom {
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate3d(-50%, -70%, 0);
  font-size: 1.4em;
}

@media only screen and (max-width: 414px) {
  .textTop {
    position: absolute;
    top: 36%;
    width: 90%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 1.5em;
    text-align: center;
  }

  .textMiddleContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .textMiddle {
    margin: 0;
    padding: 0;
    font-size: 10em;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .textBottom {
    position: absolute;
    vertical-align: middle;
    transform: translate3d(-50%, -70%, 0);
    font-size: 1.2em;
    text-align: center;
    top: 64%;
  }
}

@media screen and (max-height: 666px) {
  .textTop {
    position: absolute;
    top: 16%;
    width: 90%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 1.5em;
    text-align: center;
  }

  .textMiddleContainer {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .textMiddle {
    margin: 0;
    padding: 0;
    font-size: 10em;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .textBottom {
    position: absolute;
    top: 62%;
    vertical-align: middle;
    transform: translate3d(-50%, -70%, 0);
    font-size: 1.4em;
    text-align: center;
  }
}