@import "../../index.css";

.portfolio {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lightgrey);
}

.p-section-container1 {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.p-section-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.p-section-container3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.section-pf {
    position: relative;
    width: 320px;
    margin: 20px;
    color: var(--dark);
    padding: 40px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 50px 60px rgb(0 0 0 / 10%);
    text-align: center;
}

.img {
    width: 200px;
    height: 200px;
    opacity: 0.2;
    border-radius: 15px;
    transition: opacity 1s;
}

.img-showed {
    width: 240px;
    height: 240px;
    opacity: 1;
    border-radius: 15px;
    transition: width 3s, height 3s, opacity 3s;
}

.p-pf {
    text-align: center;
    font-weight: 500;
    font-size: 1.5em;
    padding-bottom: 5px;
    text-decoration: none;
    color:var(--dark);
}

@media only screen and (max-width: 414px) {
    .p-section-container1 {
        flex-direction: column;
    }

    .p-section-container3 {
        flex-direction: column;
    }
}

@media only screen and (min-width: 730px) and (max-width:1102px) {
    .p-section-container3 {
        flex-direction: row;
    }
}

@media only screen and (min-width:1103px) {
    .p-section-container2 {
        flex-direction: column;
    }
    
    .p-section-container3 {
        flex-direction: column;
    }
}