@import "../../index.css";

.footer-container {
    padding: 40px 30px 20px 30px;
    background: linear-gradient(30deg, var(--green) 0%, var(--orange) 100%);
    height: 100%;
    border-radius: 25px 25px 0 0;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-evenly;
    margin-top: 10px;

}

.footer-container div {
    margin: 0px;
    padding: 0px;
    min-width: 200px;
    height: 200px;
    text-align: center;
}

@media only screen and (max-width: 414px) {
    .footer-container {
        flex-direction: column;
    }
}

@media only screen and (min-width: 1025px) {
    .footer-container {
        padding: 40px 200px 20px 200px;
    }
}

.footer-container h1 {
    font-size: 5em;
    text-align: center;
    margin: 0;
    margin-top: 20px;
    font-family: 'Staatliches', cursive;
}

.footer-container h2 {
    font-size: 2em;
    margin: 5px;
}

.footer-container h4 {
    text-align: center;
    margin-top: -10px;
}

.footer-container ul {
    padding-left: 10px;
}

.footer-container ul li {
    font-weight: 500;
    font-size: 1.1em;
    padding-bottom: 5px;
    list-style: none;
}

.footer-container p {
    font-weight: 500;
    font-size: 1.2em;
    padding-bottom: 0px;
    margin: 4px;
}

.footer-container a {
    text-decoration: none;
}

.sns-icons {
    display: block;
    padding: 10px !important;
    height: 50px;
}

.sns-icons a {
    font-size: 2em;
    margin: 10px;
}