@import "../../index.css";

.aboutus {
    font-weight: 200;
    color: var(--white);
    padding: 50px 20px 50px 20px;
    background-color: var(--dark);
    overflow: hidden;
}

.title {
    display: flex;
    justify-content: left;
    width: 60%;
    height: 100%;
    margin: 0 auto;
}

.title h1 {
    background: linear-gradient(30deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.4em;
    font-family: 'Staatliches', cursive;
}

.planeContainer {
    position: relative;
    width: 30%;
    padding-top: 20px;
    padding-left: 30px;
}

.plane {
    z-index: 200;
    position: absolute;
    font-size: 8em;
    transform: rotate(-90deg);
    left: 600px;
    top: -20px
}

.plane-showed {
    z-index: 200;
    font-size: 2em;
    position: absolute;
    transform: rotate(-90deg);
    left: 5px;
    transition: font-size 10s, left 10s;
}

.content {
    height: 100%;
    width: 60%;
    margin: 0 auto;
    font-size: 1.2em;
}

.content h2{
    font-size: 1.4em;
}

@media only screen and (max-width: 414px) {
    .title {
        width: 95%;
    }

    .content {
        width: 95%;
    }

    .planeContainer {
        position: relative;
        width: 30%;
        padding-top: 20px;
        padding-left: 30px;
    }

    .plane {
        position: absolute;
        font-size: 8em;
        transform: rotate(-90deg);
        left: 400px;
        top: -20px
    }

    .plane-showed {
        font-size: 2em;
        position: absolute;
        transform: rotate(-90deg);
        left: 5px;
        transition: font-size 5s, left 5s;
    }
}

@media only screen and (min-width: 730px) and (max-width:1102px) {
    .planeContainer {
        position: relative;
        width: 50%;
        padding-top: 20px;
        padding-left: 30px;
    }

    .plane {
        position: absolute;
        font-size: 8em;
        transform: rotate(-90deg);
        left: 800px;
        top: -20px
    }

    .plane-showed {
        font-size: 2em;
        position: absolute;
        transform: rotate(-90deg);
        left: 5px;
        transition: font-size 10s, left 10s;
    }
}

@media only screen and (min-width:1103px) {
    .planeContainer {
        width: 70%;
        padding-top: 20px;
        padding-left: 30px;
    }

    .plane {
        position: absolute;
        font-size: 8em;
        transform: rotate(-90deg);
        left: 800px;
        top: -20px
    }

    .plane-showed {
        font-size: 2em;
        position: absolute;
        transform: rotate(-90deg);
        left: 5px;
        transition: font-size 10s, left 10s;
    }
}